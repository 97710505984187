import React, { FunctionComponent } from 'react'
import styled, { keyframes } from 'styled-components'
import SvgJapan from '../generated/svg/japan'
import SvgLogo2 from '../generated/svg/logo2'
import { Mbr } from '../components/br'
import { pageMaxWidth } from '../styles/page-max-width'
import { Animate } from '../components/animate'

const slideIn = keyframes`
  from {
    transform: translateY(1rem);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
`

const Outer = styled.div`
  position: relative;
  padding-top: 10.5625rem;
  padding-bottom: 10.5625rem;
  scroll-margin-top: calc(var(--header-height) + 4rem);
  @media (max-width: 40rem){
    padding-top: 7.125rem;
    padding-bottom: 7.125rem;
  }
`

const Bg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  svg {
    bottom: 0;
    left: 50%;
    position: absolute;
    display: block;
    width: 36.125rem;
    height: auto;
    @media (max-width: 68rem){
      right: 0;
      left: auto;
    }
    @media (max-width: 40rem){
      width: 95%;
      right: -5%;
    }
  }
`

const Inner = styled.div`
  ${pageMaxWidth}
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Logo = styled.div`
  opacity: 0;
  .animate & {
    animation: ${slideIn} 1s 0.4s both;
  }
  svg {
    display: block;
    height: 7.1875rem;
    width: auto;
    @media (max-width: 40rem){
      height: 4.375rem;
    }
  }
`

const Text1 = styled.div`
  font-size: 1.75rem;
  line-height: 1.8928571428571428;
  letter-spacing: 0.16em;
  margin-top: 2rem;
  text-align: center;
  font-family: var(--serif-font);
  font-weight: 600;
  opacity: 0;
  .animate & {
    animation: ${slideIn} 1s 0.4s both;
  }
  @media (max-width: 40rem){
    font-size: 1.25rem;
    line-height: 1.5;
    letter-spacing: 0.16em;
  }
  small {
    @media (max-width: 60rem){
      font-size: 1.2rem;
    }
    @media (max-width: 40rem){
      font-size: 0.9311828017234802rem;
      line-height: 2.0625000040006003;
      letter-spacing: 0.04em;
      margin-top: 1rem;
      display: block;
    }
  }
`

const Text2 = styled.div`
  line-height: 2.625;
  letter-spacing: 0.1em;
  margin-top: 4rem;
  text-align: center;
  opacity: 0;
  .animate & {
    animation: ${slideIn} 1s 0.4s both;
  }
  @media (max-width: 40rem){
    margin-top: 1.6875rem;
    font-size: 0.9375rem;
    line-height: 1.9;
    letter-spacing: 0.02em;
  }
`

type TopAboutSectionProps = {

}
export const TopAboutSection: FunctionComponent<TopAboutSectionProps> = () => {
  return <Outer id="about">
    <Bg>
      <SvgJapan />
    </Bg>
    <Inner>
      <Animate>
        <Logo>
          <SvgLogo2 />
        </Logo>
      </Animate>
      <Animate>
        <Text1>
        そこでしか、<Mbr />見つけられないものがある。
        </Text1>
      </Animate>
      <Animate>
        <Text2>
          その地域ならではの魅力あふれる<Mbr />人・モノ・コトを<br />
          ダイナースクラブが確かな目利き力をもって<Mbr />皆様にお届けします。<br />
          <br />
          その地域でしか味わえない<Mbr />稀少な食を味わい、香りを愉しむ。<br />
          その地域の文化や歴史を知り、体験し、<Mbr />これまで人から人へ紡がれてきた想いを<Mbr />共有する。<br />
          <br />
          あなたのもう一つのふるさとが<Mbr />見つかることを願って。
        </Text2>
      </Animate>
    </Inner>
  </Outer>
}
