import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { usePostsQuery } from '../generated/graphql'
import dayjs from 'dayjs'
import { SvgRightAngle } from '../components/svg'
import { pageMaxWidth } from '../styles/page-max-width'

const Outer = styled.div`
  ${pageMaxWidth}
  margin-bottom: 3.5rem;
`

const Inner = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  border: 1px solid var(--blue);
  column-gap: 1rem;
  padding: 0 1.5rem;
  @media (max-width: 40rem){
    grid-template-columns: auto 1fr auto;
  }
`

const Item = styled.a`
display: grid;
  grid-template-columns: subgrid;
  grid-column: 1 / -1;
  padding: 1.0625rem 0;
  align-items: center;
  transition: opacity 0.4s;
  @media (max-width: 40rem){
    grid-row: span 2;
  }
  & + & {
    border-top: 1px dashed #838383;
  }
  &:hover {
    opacity: 0.5;
  }
`

const PostDate = styled.div`
font-size: 0.875rem;
line-height: 1.3571428571428572;
letter-spacing: 0.02em;
font-weight: 500;
opacity: 0.5;
@media (max-width: 40rem){
  font-size: 0.8125rem;
line-height: 1.4615384615384615;
letter-spacing: 0.02em;
font-weight: 500;
  grid-column: 1 / -1;
  margin-bottom: 0.5rem;
}
`

const New = styled.div`
font-size: 0.75rem;
line-height: 1.5833333333333333;
letter-spacing: 0.02em;
font-weight: 600;
color: white;
background-color: #AD0000;
padding: 0.25rem 1.25rem;
`

const Title = styled.div`
font-size: 0.875rem;
line-height: 1.3571428571428572;
letter-spacing: 0em;
font-weight: 600;
color: var(--blue);
`

const LinkLabelContainer = styled.div`
font-size: 0.875rem;
line-height: 1.3571428571428572;
letter-spacing: 0.02em;
font-weight: 500;
color: var(--blue);
display: flex;
gap: 0.5rem;
align-items: center;
text-decoration: underline;

  svg {
    display: block;
    height: 1em;
    width: auto;
  }
`

const LinkLabel = styled.div`
  @media (max-width: 40rem){
    display: none;
  }
`

type TopNewsSectionProps = {

}
export const TopNewsSection: FunctionComponent<TopNewsSectionProps> = () => {
  const postsQuery = usePostsQuery()
  const posts = postsQuery.data?.posts?.nodes ?? []
  return posts.length > 0 && <Outer>
    <Inner>
      {
        postsQuery.data?.posts?.nodes.map(post => {
          return <Item key={post.id} {...(post.postFields?.link?.url ? {href: post.postFields?.link?.url} : {})}>
            <PostDate>
              {dayjs(post.date).format('YYYY.MM.DD')}
            </PostDate>
            {
              post.postFields?.isnew && <New>NEW</New>
            }
            <Title>
              {post.title}
            </Title>
            <LinkLabelContainer>
              <LinkLabel>{post.postFields?.link?.label}</LinkLabel>
              <SvgRightAngle size={12}/>
            </LinkLabelContainer>
          </Item>
        })
      }
    </Inner>
  </Outer>
}
