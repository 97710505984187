import * as React from "react";
import type { SVGProps } from "react";
const SvgTi5 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={39}
    fill="none"
    viewBox="0 0 42 39"
    {...props}
  >
    <path
      stroke="#0065AC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.8}
      d="m39.16 22.747.14-.041a2.84 2.84 0 0 0 1.873-3.553L36.03 2.522A2.84 2.84 0 0 0 32.477.648L3.005 9.764a2.84 2.84 0 0 0-1.877 3.553l1.499 4.849"
    />
    <path
      stroke="#0065AC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.8}
      d="M36.32 14.746H5.47a2.84 2.84 0 0 0-2.842 2.841v17.409a2.84 2.84 0 0 0 2.841 2.841h30.85a2.84 2.84 0 0 0 2.842-2.841V17.587a2.84 2.84 0 0 0-2.841-2.84"
    />
    <path
      stroke="#0065AC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.8}
      d="M39.161 18.499H2.628v4.552H39.16zM13.455 28.007h-6.11c-.702 0-1.27.568-1.27 1.269v4.34c0 .7.568 1.268 1.27 1.268h6.11c.7 0 1.269-.568 1.269-1.269v-4.34c0-.7-.568-1.268-1.269-1.268M10.433 28.007v6.877"
    />
    <path
      stroke="#0065AC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.8}
      d="M11.946 34.899v-4.525h2.776M11.948 32.54h2.776M6.473 32.364h3.98M6.473 30.374h3.98M28.01 30.741h7.5M31.759 32.969h3.75M9.41 20.155h3.925M6.45 20.155h.885"
    />
  </svg>
);
export default SvgTi5;
