import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTopPageSettingsQuery } from '../generated/graphql'
import { useBreakpoint } from '../hooks/use-break-point'
import { SvgLeftAngle, SvgRightAngle } from './svg'
import { useSwiperClass } from '../hooks/use-swiper-class'
import classNames from 'classnames'

const Outer = styled.div`
  position: relative;
`

const SlideInner = styled.div`
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const Controls = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  z-index: 2;
  box-sizing: border-box;
`

const Button = styled.div`
  pointer-events: all;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  padding: var(--page-margin);
  &.disabled {
    opacity: 0;
    pointer-events: none;
  }
  svg {
    display: block;
    height: 2.625rem;
    width: auto;
  }
`

type TopSliderProps = {

}
export const TopSlider: FunctionComponent<TopSliderProps> = () => {
  const topPageSettingsQuery = useTopPageSettingsQuery()
  const isMobile = useBreakpoint('40rem')
  const {ref, slideNext, slidePrev, hasNext, hasPrev} = useSwiperClass()
  
  return <Outer>
    <Swiper
      modules={[
        Autoplay
      ]}
      onSwiper={ref}
      loop
      autoplay={{
        delay: 3000,
        disableOnInteraction: true,
      }}
    >
      {
        topPageSettingsQuery.data?.topPageSettings?.topPageFields?.slide?.map((slide, i) => {
          return <SwiperSlide key={i}>
            <SlideInner key={i}>
              <img src={(isMobile ? slide?.mobileImage?.node.mediaItemUrl : slide?.image?.node.mediaItemUrl) ?? ''} />
            </SlideInner>
          </SwiperSlide>
        })
      }
    </Swiper>
    <Controls>
      {
        (topPageSettingsQuery.data?.topPageSettings?.topPageFields?.slide?.length ?? 0) > 0 && <>
          <Button onClick={() => slidePrev()}>
            <SvgLeftAngle />
          </Button>
          <Button onClick={() => slideNext()}>
            <SvgRightAngle />
          </Button>
        </>
      }
    </Controls>
  </Outer>
}