import * as React from "react";
import type { SVGProps } from "react";
const SvgTi4 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={38}
    height={39}
    fill="none"
    viewBox="0 0 38 39"
    {...props}
  >
    <path
      stroke="#0065AC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.804}
      d="M18.868 24.275a3.29 3.29 0 0 0 3.298-3.283 3.29 3.29 0 0 0-3.299-3.282 3.29 3.29 0 0 0-3.298 3.282 3.29 3.29 0 0 0 3.298 3.282M23.066 28.934V38M14.726 38v-9.066M5.177 38v-9.066M18.867 37.986v-3.488"
    />
    <circle cx={23.066} cy={34.498} r={0.597} fill="#0065AC" />
    <path
      stroke="#0065AC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.804}
      d="M18.903 24.274h-1.79c-2.466 0-5.371.336-5.371 5.45v3.004a2.377 2.377 0 0 0 2.387 2.367h.597M9.32 24.275a3.29 3.29 0 0 0 3.298-3.283 3.29 3.29 0 0 0-3.299-3.282 3.29 3.29 0 0 0-3.298 3.282 3.29 3.29 0 0 0 3.298 3.282"
    />
    <path
      stroke="#0065AC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.804}
      d="M9.355 24.274h-1.79c-2.467 0-5.371.336-5.371 5.45v3.004a2.377 2.377 0 0 0 2.387 2.367h.596M32.63 38v-9.066M28.488 24.275a3.29 3.29 0 0 0 3.298-3.283 3.29 3.29 0 0 0-3.298-3.282 3.29 3.29 0 0 0-3.299 3.282 3.29 3.29 0 0 0 3.299 3.282"
    />
    <path
      stroke="#0065AC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.804}
      d="M28.452 24.274h1.79c2.466 0 5.371.336 5.371 5.45v3.004a2.377 2.377 0 0 1-2.387 2.367h-.597M18.903 24.274h1.79c2.467 0 5.372.336 5.372 5.45v3.004a2.377 2.377 0 0 1-2.388 2.367h-.596M36.807 3.387v7.161a2.386 2.386 0 0 1-2.388 2.387h-2.983l-2.984 2.984v-2.984h-8.355l-1.194 2.984-1.79-2.984H9.355v2.984L6.37 12.935H3.387A2.386 2.386 0 0 1 1 10.548v-7.16A2.386 2.386 0 0 1 3.387 1H34.42a2.386 2.386 0 0 1 2.387 2.387"
    />
    <path
      stroke="#0065AC"
      strokeLinecap="round"
      strokeWidth={0.804}
      d="M7.847 5.228h21.71M7.847 8.444H23.93"
    />
  </svg>
);
export default SvgTi4;
