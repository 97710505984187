import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { TopSlider } from '../components/top-slider'
import { PageOuter } from '../components/page-outer'
import { TopLinks } from '../components/top-links'
import { VerticalSpacer } from '../components/spacer'
import { TopAboutSection } from '../sections/top-about-section'
import { TopShopListSection } from '../sections/top-shop-list-section'
import { TopFurusatoSection } from '../sections/top-furusato-section'
import { TopQuestionnaireSection } from '../sections/top-questionnaire-section'
import { DinersSection } from '../sections/diners-section'
import { TopNewsSection } from '../sections/top-news-section'

const Outer = styled.div``

type TopPageProps = {

}

export const TopPage: FunctionComponent<TopPageProps> = () => {
  return <PageOuter>
    <Outer>
      <TopSlider />
      <VerticalSpacer length='3.3125rem' mLength='1.875rem'/>
      <TopNewsSection />
      <TopLinks />
      <VerticalSpacer length='5rem' mLength='5rem'/>
      <TopShopListSection />
      <TopQuestionnaireSection />
      <DinersSection />
	    <TopAboutSection />
    </Outer>
  </PageOuter>
}
