import * as React from "react";
import type { SVGProps } from "react";
const SvgTi2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={38}
    fill="none"
    viewBox="0 0 42 38"
    {...props}
  >
    <path
      fill="#0065AC"
      d="M24.563 23.676h9.473v13.322h-9.473zM.839 11.872h7.294v2.646a3.65 3.65 0 0 1-3.647 3.648 3.6 3.6 0 0 1-1.898-.535A3.66 3.66 0 0 1 .84 14.518zm9.887-8.613-2.48 7.774H1.118L6.26 1.36h5.071zm6.071 3.189-.488 4.585H9.128l3.085-9.672h5.125zm6.528-5.087.481 4.522.548 5.15h-7.2l.54-5.087.489-4.585zm5.969 0 .882 2.766 2.204 6.906h-7.182l-.481-4.522-.548-5.15zM16.267 14.518a3.65 3.65 0 0 1-3.647 3.647 3.65 3.65 0 0 1-3.647-3.647v-2.646h7.294zm8.134 0a3.65 3.65 0 0 1-3.647 3.647 3.65 3.65 0 0 1-3.648-3.647v-2.646h7.295zm8.134 0a3.65 3.65 0 0 1-3.648 3.647 3.65 3.65 0 0 1-3.647-3.647v-2.646h7.295zm8.133 0a3.64 3.64 0 0 1-2.169 3.333 3.6 3.6 0 0 1-1.478.315 3.65 3.65 0 0 1-3.647-3.648v-2.646h7.294zM35.246 1.361l5.143 9.672h-7.128L30.175 1.36zM3.008 36.998V18.753c.463.163.96.252 1.478.252a4.49 4.49 0 0 0 4.067-2.595 4.49 4.49 0 0 0 4.067 2.595 4.49 4.49 0 0 0 4.067-2.595 4.49 4.49 0 0 0 4.067 2.595 4.49 4.49 0 0 0 4.067-2.595 4.49 4.49 0 0 0 4.067 2.595 4.49 4.49 0 0 0 4.067-2.595 4.49 4.49 0 0 0 4.067 2.595c.517 0 1.014-.09 1.478-.252v18.245h-3.624V23.257a.42.42 0 0 0-.42-.42H24.144a.42.42 0 0 0-.42.42v13.741zm35.911.84a.42.42 0 0 0 .42-.42v-19.06a4.49 4.49 0 0 0 2.169-3.84v-3.073a.4.4 0 0 0-.01-.083q-.004-.01-.006-.019a.4.4 0 0 0-.028-.074l-.005-.013L35.869.744a.42.42 0 0 0-.37-.223H6.009a.42.42 0 0 0-.37.223L.048 11.256l-.005.012a.4.4 0 0 0-.028.075l-.006.018a.4.4 0 0 0-.01.083v3.074c0 1.627.87 3.054 2.169 3.84v19.06c0 .232.188.42.42.42z"
    />
    <path
      fill="#0065AC"
      d="M19.437 29.636H6.804v-5.96h12.633zm.42-6.8H6.384a.42.42 0 0 0-.42.42v6.8c0 .232.188.42.42.42h13.473a.42.42 0 0 0 .42-.42v-6.8a.42.42 0 0 0-.42-.42"
    />
  </svg>
);
export default SvgTi2;
