import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { SvgRightAngle } from './svg'
import SvgTi1 from '../generated/svg/ti-1'
import SvgTi2 from '../generated/svg/ti-2'
import SvgTi3 from '../generated/svg/ti-3'
import SvgTi4 from '../generated/svg/ti-4'
import SvgTi5 from '../generated/svg/ti-5'
import { pageMaxWidth } from '../styles/page-max-width'
import { querySelectorWithShadow } from '../utils/query-selector-with-shadow'

const Outer = styled.div``

const Inner = styled.div`
  ${pageMaxWidth}
`

const List = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  @media (max-width: 40rem){
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.625rem;
  }
`

const Item = styled.a`
  background-color: color-mix(in srgb, #0065AC 8%, transparent);
  aspect-ratio: 1 / 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 100rem;
  height: 9.0625rem;
  width: auto;
  justify-content: center;
  @media (max-width: 40rem){
    height: auto;
    border-radius: 0;
    aspect-ratio: auto;
    padding: 1.5rem 0;
  }
`

const IconContainer = styled.div`
  svg {
    display: block;
    height: 2.5rem;
    width: auto;
    @media (max-width: 40rem){
      height: 2rem;
    }
  }
`

const Label = styled.div`
  font-size: 0.8662683367729187rem;
  line-height: 1.3846153846153846;
  letter-spacing: 0.02em;
  text-align: center;
  font-weight: 600;
  color: var(--blue);
  margin-top: 0.5rem;
  @media (max-width: 40rem){
    font-size: 0.8374997973442078rem;
  line-height: 1.4115386791536353;
  }
`

const Arrow = styled.div`
  margin-bottom: -1rem;
  color: var(--blue);
  transition: transform 0.4s;
  @media (max-width: 40rem){
    margin-top: 0;
  }
  ${Item}:hover & {
    transform: translateY(0.5rem);
  }
  svg {
    display: block;
    width: 0.8rem;
    height: auto;
    transform: rotate(90deg) scaleX(65%);
  }
`

type TopLinksProps = {

}
export const TopLinks: FunctionComponent<TopLinksProps> = () => {
  return <Outer>
    <Inner>
      <List>
        <Item href="/#about" onClick={e => {
          e.preventDefault()
          querySelectorWithShadow('#about')?.scrollIntoView({behavior: 'smooth'})
        }}>
          <IconContainer>
            <SvgTi1 />
          </IconContainer>
          <Label>
            ふるさと ときめき<br />
            プロジェクトとは
          </Label>
          <Arrow>
            <SvgRightAngle strokeWidth={2} />
          </Arrow>
        </Item>
        <Item href="/#shops" onClick={e => {
          e.preventDefault()
          querySelectorWithShadow('#shops')?.scrollIntoView({behavior: 'smooth'})
        }}>
          <IconContainer>
            <SvgTi2 />
          </IconContainer>
          <Label>
            参加店(優待)<br />
            一覧
          </Label>
          <Arrow>
            <SvgRightAngle strokeWidth={2} />
          </Arrow>
        </Item>
        <Item href="/#questionnaire" onClick={e => {
          e.preventDefault()
          querySelectorWithShadow('#questionnaire')?.scrollIntoView({behavior: 'smooth'})
        }}>
          <IconContainer>
            <SvgTi4 />
          </IconContainer>
          <Label>
            皆様の声を<br />
            お届けください
          </Label>
          <Arrow>
            <SvgRightAngle strokeWidth={2} />
          </Arrow>
        </Item>
        <Item href="/#diners" onClick={e => {
          e.preventDefault()
          querySelectorWithShadow('#diners')?.scrollIntoView({behavior: 'smooth'})
        }}>
          <IconContainer>
            <SvgTi5 />
          </IconContainer>
          <Label>
            ダイナース<br />
            クラブカード
          </Label>
          <Arrow>
            <SvgRightAngle strokeWidth={2} />
          </Arrow>
        </Item>
      </List>
    </Inner>
  </Outer>
}
