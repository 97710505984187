import React, { FunctionComponent, useCallback, useRef, useState } from 'react'

const useSize = () => {
  const observerRef = useRef<ResizeObserver>()
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const ref = useCallback((node: SVGSVGElement | null) => {
    if(observerRef.current){
      observerRef.current.disconnect()
      observerRef.current = undefined
    }
    if(node){
      const observer = observerRef.current = new ResizeObserver(() => {
        setWidth(node.clientWidth)
        setHeight(node.clientHeight)
      })
      observer.observe(node)
    }
  }, [])
  return {
    ref,
    width,
    height,
    short: Math.min(width, height),
    long: Math.max(width, height)
  }
}

type SvgProps = {
  size?: number,
  strokeWidth?: number
}

type SvgPlusProps = SvgProps & {
}
export const SvgPlus: FunctionComponent<SvgPlusProps> = (props) => {
  const {ref, short, long} = useSize()
  const size = props.size ?? short
  const strokeWidth = props.strokeWidth ?? 1
  return <svg ref={ref} viewBox={`0 0 ${long} ${long}`}>
    <path d={`M0 ${size / 2}L${size} ${size / 2}M ${size / 2} 0L${size / 2} ${size}`} fill="none" stroke="currentColor" strokeWidth={strokeWidth}/>
  </svg>
}

type SvgMinusProps = SvgProps & {
}
export const SvgMinus: FunctionComponent<SvgMinusProps> = (props) => {
  const {ref, short, long} = useSize()
  const size = props.size ?? short
  const strokeWidth = props.strokeWidth ?? 1
  return <svg ref={ref} viewBox={`0 0 ${long} ${long}`}>
    <path d={`M0 ${size / 2}L${size - 0} ${size / 2}`} fill="none" stroke="currentColor" strokeWidth={strokeWidth}/>
  </svg>
}

type SvgCrossProps = SvgProps & {

}

export const SvgCross: FunctionComponent<SvgCrossProps> = (props) => {
  const {ref, short, long} = useSize()
  const size = props.size ?? short
  const strokeWidth = props.strokeWidth ?? 1
  const sin45 = Math.sin(Math.PI / 180 * 45)
  const harf = size / 2 * sin45
  return <svg ref={ref} viewBox={`0 0 ${long} ${long}`}>
    <path d={`M${size / 2 - harf} ${size / 2 - harf} L${size / 2 + harf} ${size / 2 + harf} M${size / 2 - harf} ${size / 2 + harf} L${size / 2 + harf} ${size / 2 - harf}`} fill="none" stroke="currentColor" strokeWidth={strokeWidth}/>
  </svg>
}

type SvgCircleProps = SvgProps & {
}
export const SvgCircle: FunctionComponent<SvgCircleProps> = (props) => {
  const {ref, short, long} = useSize()
  const size = props.size ?? short
  const strokeWidth = props.strokeWidth ?? 1
  const r = size / 2
  return <svg ref={ref} viewBox={`0 0 ${long} ${long}`}>
    <circle cx={size / 2} cy={size / 2} r={r - strokeWidth / 2} fill="none" stroke="currentColor" strokeWidth={strokeWidth}/>
  </svg>
}

type SvgAngleProps = SvgProps & {
}
export const SvgLeftAngle: FunctionComponent<SvgAngleProps> = (props) => {
  const {ref, height, long} = useSize()
  const size = props.size ?? height
  const strokeWidth = props.strokeWidth ?? 1
  const tipMargin = strokeWidth / 2 * Math.sqrt(2)
  const endMargin = strokeWidth / 2 / Math.tan(Math.PI / 180 * 45)
  const w = (size / 2 - tipMargin) / Math.tan(Math.PI / 180 * 45)
  return <svg ref={ref} viewBox={`0 0 ${w + tipMargin + endMargin} ${long}`}>
    <path d={`M${w + endMargin} ${endMargin} L${tipMargin} ${size/2}L${w + endMargin} ${size - endMargin}`} fill="none" stroke="currentColor" strokeWidth={strokeWidth}/>
  </svg>
}
export const SvgRightAngle: FunctionComponent<SvgAngleProps> = (props) => {
  const {ref, height, long} = useSize()
  const size = props.size ?? height
  const strokeWidth = props.strokeWidth ?? 1
  const tipMargin = strokeWidth / 2 * Math.sqrt(2)
  const endMargin = strokeWidth / 2 / Math.tan(Math.PI / 180 * 45)
  const w = (size / 2 - tipMargin) / Math.tan(Math.PI / 180 * 45)
  return <svg ref={ref} viewBox={`0 0 ${w + tipMargin + endMargin} ${long}`}>
    <path d={`M${endMargin} ${endMargin} L${w + tipMargin} ${size/2}L${endMargin} ${size - endMargin}`} fill="none" stroke="currentColor" strokeWidth={strokeWidth}/>
  </svg>
}